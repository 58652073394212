import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [items, setItems] = useState([]);
  const [apiKey, setApiKey] = useState('');
  const [username, setUsername] = useState('admin');
  const [password, setPassword] = useState('');



  const fetchPendingItems = async (apiKey) => {
    try {
      const response = await axios.get('https://backend.empowerbackend.com/admin/pending', {
        mode: 'cors',
        headers: {
            'X-Api-Key': `${apiKey}`,
            'username': `${username}`,
            'password': `${password}`
        },
      });
      setItems(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const savedApiKey = localStorage.getItem('apiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
      fetchPendingItems(savedApiKey);
    }
  }, []);
// https://backend.empowerbackend.com/submit/ec/admin/decision/?id=${itemId}&approved=true
// https://backend.empowerbackend.com/submit/ec/admin/decision/?id=${itemId}&approved=false
  const approveItem = async (itemId) => {
    try {
      const response = await axios.get(`https://backend.empowerbackend.com/submit/ec/admin/decision/?id=${itemId}&approved=true`, {
        mode: 'cors',
        headers: {


            'X-Api-Key': `${apiKey}`,
            'username': `${username}`,
            'password': `${password}`
        },
      });
      fetchPendingItems(apiKey);
    } catch (error) {
      console.error(error);
    }
  };
  const denyItem = async (itemId) => {
    try {
      const response = await axios.get(`https://backend.empowerbackend.com/submit/ec/admin/decision/?id=${itemId}&approved=false`, {
        mode: 'cors',

        headers: {
            'X-Api-Key': `${apiKey}`,
            'username': `${username}`,
            'password': `${password}`
        },
      });
      fetchPendingItems(apiKey);
    } catch (error) {
      console.error(error);
    }
  };


 
  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {

    setPassword(event.target.value);
  };

  const handleSaveApiKey = () => {
    localStorage.setItem('apiKey', apiKey);
    fetchPendingItems(apiKey);
  };


  return (
    <div>
      <h1>Pending Extracurricular Items</h1>
      <div>
        <label htmlFor="apiKey">API Key:</label>
        <input
          type="text"
          id="apiKey"
          value={apiKey}
          onChange={handleApiKeyChange}
        />
        <button onClick={handleSaveApiKey}>Save & Fetch</button>
      </div>
      <div>
        <label htmlFor="username">Username:</label>
        <input type="text" id="username" value={username} onChange={handleUsernameChange}/>
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input type="password" id="password" value={password} onChange={handlePasswordChange}/>
      </div>
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <h2>{item.name}</h2>
            <p>{item.description}</p>
            <p>{item.tags.map(item=> (<span key={item}> {item}, </span>))}</p>
            <p>{item.website}</p>
            <button onClick={() => approveItem(item.id)}>Approve</button>
            <button onClick={() => denyItem(item.id)}>Deny</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;

